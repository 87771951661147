/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"
import List from "../../components/List"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Other Industries"
      description="At SimCrest we know how to make Microsoft Dynamics Business Central work for your businesses."
      linkSubPageGroupID="IndustriesGroup"
      infoList={[
        {
          title:
            "Industry-Specific Microsoft Dynamics Business Central Solutions from SimCrest",
          text: (
            <p>
              At SimCrest we know how to make Microsoft Dynamics Business
              Central work for your businesses. We have extensive experience
              working with small and mid-sized organizations in diverse
              industies.
              <br />
              <br />
              Our range of services help you take control of purchasing, sales
              and distribution operations, order entry and procurement, and lots
              of other business needs specific to industries including (but not
              limited to):
              <List
                className="concise dark"
                items={[
                  {
                    text: `Chemical Manufacturing`,
                  },
                  {
                    text: `Consumer Products`,
                  },
                  {
                    text: `Construction & Operation`,
                  },
                  {
                    text: `Credit Union`,
                  },
                  {
                    text: `High Tech`,
                  },
                  {
                    text: `Hospitality`,
                  },
                  {
                    text: `Industrial Machinery & Components`,
                  },
                  {
                    text: `Oil & Gas`,
                  },
                  {
                    text: `Public Administration`,
                  },
                  {
                    text: `Specialty Retail`,
                  },
                  {
                    text: `Point of Sales`,
                  },
                  {
                    text: `Web Shops with Back-End Integration`,
                  },
                  {
                    text: `Job Shop Manufacturing`,
                  },
                  {
                    text: `Recruiting`,
                  },
                  {
                    text: `Non-Profit`,
                  },
                ]}
              />
            </p>
          ),
        },
      ]}
    />
  )
}
